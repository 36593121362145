<template>
<div>
   <CCard>
      <CCardHeader>
        <CIcon name="cil-filter"/><strong> Filter By User</strong>
      </CCardHeader>
      <CCardBody>
        <CRow class="col-md-12">
                    <CSelect
                    class="col-md-3"
                    :options="user_opt"
                    :value.sync="input.userid"
                    size="sm"
                    placeholder='select'
                    @change="getAllData('filter')"
                    />
        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
    <CCardHeader>
      <CIcon name="cil-grid"/><strong>Campaign List</strong>
    </CCardHeader>
    <CCardBody>
      <div v-if="loading==true">Loading . . .</div>
      <div v-else>
        <CRow>
        <CCol>
          <CRow class="col-md-12">
            <label>Filter :</label>
            <CInput class="col-md-8"
            @keyup="customFilter"
            v-model="filter"
            type="search"
            placeholder="Type to Search" />
            <label>Total Rows : {{totalRows}}</label>
          </CRow>
        </CCol>
        <CCol>
      <div class="card-header-actions">
            <small class="text-muted"
              >
        <multiselect
              :limit="2"
              :clear-on-select="false"
              :close-on-select="true"
              :multiple="false"
              :options="perPage"
              :preselect-first="false"
              :preserve-search="false"
              @input="checkPage()"
              placeholder="PerPage"
              v-model="tableperpage"
            ></multiselect></small
            ></div>
    </CCol>
    </CRow>
        <CDataTable
      :items="items"
      :fields="fields"
      :dark="false"
      :items-per-page="tableperpage"
      pagination
      sorter
      hover
      striped
      border
      small
      fixed
      light
    >
    <!-- column-filter -->
    <template #campname="{item}"
            ><td>
                {{ item.campname }}
              <span class="ml-1" v-if="item.customCR.Enable == true" style="flot:right;">
              <CBadge color="danger" v-if="item.dangCR > item.customCR.dangerous">Danger CR</CBadge>
              <CBadge class="ml-1" color="warning" v-if="item.susCR > item.customCR.suspicious">Suspecius CR</CBadge>
              </span>
            </td></template
          >
          <template #status="{item}"
            ><td>
              <span v-if="item.status == true">Active</span>
              <span v-if="item.status == false">Inactive</span>
              </td></template
          >
    <!-- <template #offername="{item}"><td>{{ item.offername }}</td></template>

    <template #offerid="{item}"><td>{{ offer_opt[item.offerid]}}</td></template>
    <template #tsid="{item}"><td>{{ ts_opt[item.tsid]}}</td></template> -->
    
      </CDataTable> </div>
    </CCardBody>
    </CCard>
</div>
</template>

<script>
import CampaignService from '../../services/campaignControlService'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Multiselect from "vue-multiselect";
import ManagerService from "../../services/managerControlService";
import OfferService from "../../services/offerControlService";
import Config from "../../services/config";

import 'sweetalert2/src/sweetalert2.scss'
export default {
  name: 'ListCampaign',
  components:{Multiselect},
  props:{small: Boolean},
  data() {
        return {
        user_opt:[],
        input:{userid:''},
        all_data:[],
        filter: '',
        totalRows:0,
        loading:true,
        url: "",
        collapse: false,
        url_param: {
        sub1: "sub1",
        sub2: "sub2",
        sub3: "sub3",
        sub4: "sub4",
        sub5: "sub5",
        sub6: "sub6",
        sub7: "sub7",
        sub8: "sub8",
        sub9: "sub9",
        sub10: "sub10",
        sub11: "sub11",
        sub12: "sub12",
      },
          camp_url: false,
          traffic_source_name:[],
          offer_name: [],
          tableperpage:20,
          perPage:[20,50,100],
          edittraffic:false,
          items:[],
          fields: [
          { key: "_id", label: "Id", sortable: true },
          { key: "campname", label: "Camp Name",sortable: true },
          { key: "useremail", label: "User",sortable: true },
          { key: "status", label: "Status",sortable: true },
        // { key: "action", label: "Action",sortable: true }
      ],
          startDate: 1325376000000,
          endDate: 1330560000000,
          filter_dash: {
          edate: new Date(),
          sdate: new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000)),
          dim: {},
          granularity: 'daily',
          projection: [],
          metrics: ["impression", "accepted", "rejected", "converted"],
        }, 
        }
        },
     async mounted(){
       if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
        }
        else{
        if(localStorage.getItem("camp_table") != null)
        this.tableperpage = parseInt(localStorage.getItem("camp_table"));
        this.getAllData();
        this.getUsers()
      }
        
      },
  methods: {
    async getUsers(){
            let response
        try {
            response = await ManagerService.getuser()
            this.users = response.message.filter(t=>{
            if(t.role != 'manager')
            return t
            })
            this.user_opt = this.users.map(m =>  m.email)
        } catch(e){
            console.error("Error in login")
            console.log(e)
        }
        },
     customFilter() {
      let search = this.filter.toString().toLowerCase()
      if(search != ''){
      let f_data = this.all_data.filter(row => row.campname.match(search))
      if(f_data){return this.items = f_data}
      }
      else{return this.items = this.all_data}
    },
      async getAllData(txt){
      try {
        var response
        if(txt){
        response = await CampaignService.getManagerCamp({users: this.input.userid})
        if(response.message == ''){
            Swal.fire({title: 'Error!',text: "Data not available for this user",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
            this.getallData()
          
          }
        }
        else{
         response = await CampaignService.getManagerCamp()
        }
      this.items = response.message;
      this.totalRows = this.items.length;
      this.loading = false;
    } catch (e) {
      // Swal.fire({title: 'Error!',text: "connection error",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
      // this.errorNotify("Error", "connection error", "danger");
      //   this.$router.push("Login");
    }
    },     
  }

}
</script>

<style scoped>
.link {
  color: blue;
}
.link:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>